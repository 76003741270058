// import fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// variables
:root {
  --pure-black-color: #000000;
  --blackcolor: #131a21;
  --white-color: #ffffff;
}

// common document styles - tags
body {
  position: relative;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1;
  color: var(--pure-black-color);
  background: #f5f7f8;
}

ul,
ol,
li {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0;
}

a {
  display: inline-block;
  color: var(--pure-black-color);
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    text-decoration: none;
    color: var(--pure-black-color);
  }
  &:active {
    color: var(--pure-black-color);
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    appearance: none;
    margin: 0;
  }
}

picture {
  display: block;
}

img {
  max-width: 100%;
}

svg,
path {
  transition: all 0.25s ease-in-out;
}

.app-loading-screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  path {
    transition: none !important;
  }

  svg {
    width: 60px;
    height: 60px;
    stroke: #ff8d70;
  }
}

.loader-icon,
.loader-icon path {
  transition: none !important;
}

.loader-icon {
  stroke: #ff8d70;
  margin: 0 auto;
  display: block;
}

.error.request-error {
  font-size: 20px;
  text-align: center;
  color: #e86756;
  margin-top: 34px;
}
