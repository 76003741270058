
.select {
  position: relative;

  &.open .select__control {
    border-color: #3E81F7;
  }

  .select__icon {
    display: block;
    // transition: all 0.25s ease-in-out;
  }

  &.open .select__icon {
    transform: rotateX(180deg);
  }

  &__label-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  &__label {
    display: block;
    margin: 0 12px 0 0;
    font-size: 20px;
    font-weight: 500;
    color: #081F31;
    span {
        margin-right: 12px;
    }

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__control {
    padding: 18px 22px;
    font-size: 18px;
    background: #F5F7F8;
    border: 1px solid rgba(#000000, 0.08);
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    color: rgba(#545F6C, 0.69);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &.selected {
      color: #081F31;
    }

    &.error-field {
      border: 1px solid #E86756 !important;
      color: #E86756 !important;
    }
  }

  &__dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + .5rem));
    background: #F5F7F8;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 20px 24px;
    z-index: 10;
    display: none;

    & > ul {
      display: flex;
      flex-flow: column nowrap;
      gap: .75rem;
      position: relative;
      width: 100%;

      .dropdown__item {
        background: #E0E0E0;
        border: 1px solid #C6C6C6;
        border-radius: 4px;

        &-inner {
          padding: .25rem .5rem;
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }

  &.open &__dropdown {
    display: block;
  }

  .hidden {
    display: none;
  }
}