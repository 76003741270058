.btn_wrap {
  position: relative;
  margin-top: 38px;
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: var(--white-color);
  background: linear-gradient(302deg, #ffc973 -7.91%, #ff8d70 81.06%);
  border: none;
  border-radius: 89px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  &:focus {
    outline: none;
  }
  &--grey {
    color: #283039;
    background: #d9d9d9;
  }
  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  & > span {
    z-index: 3;
  }

  &::before {
    display: block;
    content: "";
    background: rgba(255, 243, 224, 0);
    transition: background 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (hover: hover) {
    &:hover {
      color: var(--white-color);

      &::before {
        background: rgba(255, 243, 224, 0.17);
      }
      // background: linear-gradient(302deg, #ffc973 -7.91%, #ff8d70 81.06%);
    }
    &--grey {
      &:hover {
        color: #283039;
        background: #ebeaea;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20.5px;
    font-size: 19px;
  }
}
