.input-field {
    position: relative;
    &.is-error {
        .input-field {
            &__input {
                color: #E86756;
                border-color: #E86756;
                &::placeholder {
                    transition: color .2s ease-in-out;
                    color: rgba(#E86756, 0.69);
                }
                &:focus {
                    transition: border-color .2s ease-in-out;
                    border-color: #E86756;
                }
                transition: all .2s ease-in-out
            }
            &__error {
                display: block;
            }
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
    }
    &__label {
        display: block;
        margin: 0 12px 0 0;
        font-size: 22px;
        font-weight: 500;
        color: #081F31;
        span {
            margin-right: 12px;
        }

        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }

    &__note {
        color: #A0A8B1;
        margin-bottom: 16px;
    }
    &__tooltip {
        display: inline-block;
        padding: 0;
        min-width: 20px;
        width: 20px;
        height: 20px;
        font-size: 0;
        vertical-align: bottom;
        background: transparent;
        border: none;
        border-radius: 0;
        &:focus {
            outline: none;
        }

        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
    }
    &__tooltip-icon {
        min-width: 20px;
        width: 20px;
        height: 20px;
        color: #283039;
    }
    &__group {
        position: relative;
        z-index: 1;
        overflow: hidden;
        background: #F5F7F8;
        border-radius: 15px;
        .input-field {
            &__input {
                border: none;
                &:focus {
                    & ~ .input-field {
                        &__group-border {
                            border-color: #3E81F7;
                        }
                    }
                }
            }
        }
    }
    &__group-border {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(#000000, 0.08);
        border-radius: 15px;
        pointer-events: none;
        transition: all 0.25s ease-in-out;
    }
    &__input {
        resize: none;
        overflow: hidden;
        display: block;
        padding: 18px 32px;
        width: 100%;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.2;
        color: #081F31;
        background: var(--white-color);
        border: 1px solid #545F6C;
        border-radius: 9px;
        transition: opacity 0.25s ease-in-out, border 0.25s ease-in-out, color 0.25s ease-in-out, outline 0.25s ease-in-out, background 0.25s ease-in-out;
        &::placeholder {
            opacity: 1;
            color: rgba(#545F6C, 0.69);
        }
        &:focus {
            outline: none;
            border-color: #3E81F7;
        }

        @media screen and (max-width: 767px) {
            padding: 14px 23px;
            font-size: 18px;
            line-height: 1.25;
        }
    }
    &__error {
        display: none;
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        color: #E86756;

        @media screen and (max-width: 767px) {
            font-weight: normal;
        }
    }
}