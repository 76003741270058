.upload-file_wrap {
  position: relative;

  & > * {
    width: 100%;
    outline: none !important;
  }

  .hidden-input {
    display: none;
  }

  .field-label {
    display: block;
    margin: 0 0 13px 0;
    font-size: 20px;
    font-weight: 500;
    color: #081f31;
  }

  .upload-file {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
    background: #f5f7f8;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 14.9732px;
    padding: 18px 40px;
    cursor: pointer;

    & > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #a0a8b1;
      text-align: center;
      margin-bottom: 20px;
    }

    &__footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      gap: 0.875rem;
      margin-top: 1.875rem;
    }

    .footer-btn {
      background: #a0a8b1;
      border-radius: 10px;
      color: #282828;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      transition: background 0.2s ease-in-out;

      @media screen and (hover: hover) {
        &:hover {
          background: #c1c6cc;
        }
      }
    }

    .add-btn {
      padding: 12px 22px;
    }

    .delete-btn {
      padding: 0 10px;
    }
  }

  .uploaded-file {
    display: flex;
    margin-top: 1rem;
    justify-content: center;

    &__content {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      border: 1px solid #26ab6b;
      color: #26ab6b;
      font-size: 16px;
      cursor: default;

      .delete-btn {
        border: none;
        outline: none;
        background: inherit;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover .delete-btn {
        opacity: 1;
      }
    }
  }
}
